<template>
  <div>
    <div>
      <vs-row
        vs-type="flex"
        class="mb-4"
        vs-justify="flex-end"
        vs-align="center"
        vs-w="12"
      >
        <vs-col
          size="small"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
          vs-w="12"
        >
          <vs-input
            class="mx-2"
            type="date"
            size="small"
            label="Data"
            v-model="filtro.data"
          ></vs-input>
          <vs-input
            class="mx-2"
            type="number"
            size="small"
            label="Qtd. Técnicos"
            v-model="filtro.tecnicos_exec"
          ></vs-input>
          <vs-input
            class="mx-2"
            type="number"
            size="small"
            min="0"
            label="Qtd. Carros"
            v-model="filtro.carros"
          ></vs-input>

          <div class="mx-2">
            <vs-input
              label="Hora marcada"
              name="hora_marcada"
              v-model="filtro.horario"
              v-mask="'##:00'"
              max="24"
              size="small"
              type="datetime"
              placeholder="00:00"
              class="inputx w-full"
            />
          </div>
          <div class="mx-2">
            <label style="color: black; font-size: 0.85rem">Turnos</label>
            <br />
            <el-select
              filterable
              multiple
              collapse-tags
              clearable
              size="small"
              v-model="filtro.turnos"
            >
              <el-option
                v-for="turno in turnos"
                class="select-info"
                :value="turno.id"
                :label="turno.nome"
                :key="turno.id"
              ></el-option>
            </el-select>
          </div>
          <div class="mx-2">
            <label style="color: black; font-size: 0.85rem">Zona</label>
            <br />
            <el-select filterable clearable size="small" v-model="filtro.zona">
              <el-option
                v-for="zona in zonas"
                class="select-info"
                :value="zona.id"
                :label="zona.nome"
                :key="zona.id"
              ></el-option>
            </el-select>
          </div>
          <div class="mx-2">
            <label style="color: black; font-size: 0.85rem"
              >Tipo de Controle</label
            >
            <br />
            <el-select
              filterable
              clearable
              size="small"
              v-model="filtro.controle"
            >
              <el-option
                v-for="controle in controles"
                class="select-info"
                :value="controle.id"
                :label="controle.nome"
                :key="controle.id"
              ></el-option>
            </el-select>
          </div>
          <div class="mx-2">
            <label style="color: black; font-size: 0.85rem"
              >Tipo de Serviço</label
            >
            <br />
            <el-select
              filterable
              clearable
              size="small"
              v-model="filtro.tipo_servico"
            >
              <el-option
                v-for="tipo in tipos_servico"
                class="select-info"
                :value="tipo.id"
                :label="tipo.nome"
                :key="tipo.id"
              ></el-option>
            </el-select>
          </div>
          <el-button
            class="mx-2"
            plain
            :disabled="blockButton"
            @click="filtrar()"
            type="primary"
            size="small"
            >Filtrar</el-button
          >
        </vs-col>
      </vs-row>
    </div>
    <vs-row vs-type="flex" vs-w="12">
      <vs-col vs-w="2" class="px-2">
        <rotaLista
          @rotaEmit="rotaEmit"
          :blocked="JSON.stringify(osRota) !== JSON.stringify(osRotaOriginal)"
          @update="rotaSelecionada"
        />
      </vs-col>
      <vs-col vs-w="6" class="px-2">
        <div>
          <vx-card style="background-color: #000a12">
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
            >
              <vs-col
                vs-w="9"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div>
                  <h5 style="color: white">
                    SERVIÇOS PENDENTES:
                    {{ osAssociar.length }}
                  </h5>
                </div>
              </vs-col>
              <vs-col
                vs-w="3"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="flex-end"
              >
                <vs-button
                  color="success"
                  type="relief"
                  size="small"
                  @click="exportToExcel()"
                  >EXCEL</vs-button
                >
              </vs-col>
            </vs-row>
          </vx-card>
          <perfect-scrollbar
            class="scroll-os-assoc"
            @ps-y-reach-end="showMoreOs2Assoc()"
          >
            <div id="osAssociar" class="vs-con-loading__container">
              <div v-if="!osAssociar.length > 0">
                <div class="con-colors">
                  <ul>
                    <span
                      class="px-3 pt-2"
                      style="
                        color: grey !important;
                        font-weight: 1000 !important;
                      "
                    >
                      <vs-icon
                        size="large"
                        icon="sentiment_dissatisfied"
                      ></vs-icon>
                    </span>
                  </ul>
                </div>
              </div>
              <draggable
                :disabled="!$atividades.permissoes.includes(8)"
                v-model="osAssociarLista"
                group="lev"
              >
                <div
                  v-for="data in osAssociarLista"
                  :key="data.id"
                  class="mx-2"
                >
                  <vx-card
                    class="mr-10 my-3 os-card overflow-hidden"
                    :style="
                      data.confirmado > 0
                        ? data.confirmado == 1
                          ? ''
                          : 'background-color:#cbdef7'
                        : 'background-color: #fae1e1'
                    "
                  >
                    <template slot="no-body">
                      <div class="item-details px-2">
                        <div class="my-1">
                          <div class="w-full">
                            <vs-col vs-w="8">
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  <b>
                                    {{
                                      data.remarcado > 0
                                        ? data.data_remarcacao
                                        : data.data_competencia
                                    }}
                                  </b>
                                  &nbsp;-&nbsp; Horas:
                                  <b>{{ data.horas_exec }}</b>
                                  &nbsp;-&nbsp;Técnicos:
                                  <b>{{ data.tecnicos_exec }}</b>
                                  &nbsp;-&nbsp;
                                  <b style="color: black">
                                    {{ data.controle }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <div>
                                  <VisualizarCliente
                                    @update="filtrar()"
                                    :id_cliente="data.id_cliente"
                                    :nomeCliente="data.nomeCliente"
                                  />
                                </div>

                                <span class="item-description text-md">
                                  &nbsp;&nbsp;-&nbsp;Cidade:
                                  <b>{{ data.nomeCidade }}</b>
                                  &nbsp;-&nbsp;Bairro:
                                  <b>{{ data.nomeBairro }}</b>
                                </span>
                              </vs-col>

                              <vs-col
                                vs-w="12"
                                class="mt-1"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-md">
                                  Contrato
                                  <b>
                                    <a
                                      target="_blank"
                                      :href="'/contrato?id=' + data.id_contrato"
                                      style="color: darkblue; cursor: pointer"
                                      >{{ data.id_contrato }}</a
                                    >
                                  </b>
                                  - Serviço
                                  <b>{{ data.id }} - {{ data.tipo }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                class="mt-1"
                                vs-type="flex"
                                vs-justify="flex-start"
                                v-if="data.obs"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Obs:
                                  <b>{{ data.obs ? data.obs : "Sem obs" }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span
                                  class="item-description text-sm"
                                  v-if="data.pessoa_confirmacao"
                                >
                                  Pessoa confirmação:
                                  <b>{{ data.pessoa_confirmacao }}</b>
                                </span>
                              </vs-col>
                            </vs-col>
                            <vs-col
                              vs-w="4"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <div class="w-full">
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <Badge
                                    v-if="data.executado > 0"
                                    :text="
                                      data.executado == 1
                                        ? 'Executado'
                                        : 'Não Exec.'
                                    "
                                    :color="
                                      data.executado == 1 ? 'success' : 'danger'
                                    "
                                  ></Badge>
                                </vs-col>
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <Badge
                                    v-if="data.hora_marcada"
                                    :text="data.hora_marcada"
                                    color="warning"
                                  ></Badge>
                                  <Badge
                                    v-else
                                    :text="data.turno"
                                    color="warning"
                                  ></Badge>
                                </vs-col>
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <Badge
                                    v-if="data.fog > 0 || data.escada > 0"
                                    :text="
                                      (data.escada > 0 ? 'Escada' : '') +
                                        (data.escada > 0 && data.fog > 0
                                          ? ' e '
                                          : '') +
                                        (data.fog > 0 ? 'Fog' : '')
                                    "
                                    color="danger"
                                  ></Badge>
                                </vs-col>
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <Badge
                                    v-if="data.carros > 0"
                                    :text="data.carros + ' carros'"
                                    color="#10163a"
                                  ></Badge>
                                </vs-col>
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <Badge
                                    v-if="data.nomeZona"
                                    :text="data.nomeZona"
                                    color="success"
                                  ></Badge>
                                </vs-col>
                              </div>
                            </vs-col>
                          </div>
                        </div>
                      </div>
                    </template>
                  </vx-card>
                </div>
              </draggable>
            </div>
          </perfect-scrollbar>
        </div>
      </vs-col>
      <vs-col vs-w="4" class="px-2">
        <div>
          <vx-card card-background="#021a33" :key="reRender">
            <vs-row vs-type="flex">
              <vs-col
                vs-w="6"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <div>
                  <h5 style="color: white">
                    {{
                      rota.nome == null
                        ? "Selecione..."
                        : rota.nome + ": " + osRota.length
                    }}
                  </h5>
                </div>
              </vs-col>
              <vs-col
                vs-w="3"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="flex-end"
              >
                <vs-button
                  :disabled="
                    !(osRota.length > 0) || !$atividades.permissoes.includes(8)
                  "
                  color="danger"
                  size="small"
                  @click="osRota = []"
                  >Limpar</vs-button
                >
              </vs-col>
              <vs-col
                vs-w="3"
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="flex-end"
              >
                <vs-button
                  :disabled="
                    JSON.stringify(osRota) == JSON.stringify(osRotaOriginal) ||
                      !$atividades.permissoes.includes(8)
                  "
                  color="success"
                  size="small"
                  @click="osAssociarRota()"
                  >ASSOCIAR</vs-button
                >
              </vs-col>
            </vs-row>
          </vx-card>
          <VuePerfectScrollbar
            class="scroll-os-assoc"
            :settings="settings"
            style="background-color: #ebebeb"
          >
            <div id="osRota" class="vs-con-loading__container">
              <div v-if="!osRota.length > 0">
                <div class="con-colors">
                  <ul>
                    <span
                      class="px-3 pt-2"
                      style="
                        color: grey !important;
                        font-weight: 1000 !important;
                      "
                    >
                      <vs-icon
                        size="large"
                        icon="sentiment_dissatisfied"
                      ></vs-icon>
                    </span>
                  </ul>
                </div>
              </div>
              <draggable v-if="rota.nome != null" v-model="osRota" group="lev">
                <div v-for="data in osRota" :key="data.id" class="mx-2">
                  <vx-card
                    class="mr-10 my-3 os-card overflow-hidden"
                    :style="
                      data.confirmado > 0
                        ? data.confirmado == 1
                          ? ''
                          : 'background-color:#cbdef7'
                        : 'background-color: #fae1e1'
                    "
                  >
                    <template slot="no-body">
                      <div class="item-details px-2">
                        <div class="my-1">
                          <div class="w-full">
                            <vs-col vs-w="8">
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <div>
                                  <span class="item-description text-sm">
                                    <b>
                                      {{
                                        data.remarcado > 0
                                          ? data.data_remarcacao
                                          : data.data_competencia
                                      }}
                                    </b>
                                    &nbsp;-&nbsp;Horas:
                                    <b>{{ data.horas_exec }}</b>
                                    &nbsp;-&nbsp;Técnicos:
                                    <b>{{ data.tecnicos_exec }}</b>
                                    &nbsp;-&nbsp;
                                    <b style="color: black">
                                      {{ data.controle }}
                                    </b>
                                  </span>
                                </div>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <div>
                                  <VisualizarCliente
                                    @update="filtrar()"
                                    :id_cliente="data.id_cliente"
                                    :nomeCliente="data.nomeCliente"
                                  />
                                </div>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-md">
                                  Cidade:
                                  <b>{{ data.nomeCidade }}</b>
                                  &nbsp;-&nbsp;Bairro:
                                  <b>{{ data.nomeBairro }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                class="mt-1"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Contrato
                                  <b>
                                    <a
                                      target="_blank"
                                      :href="'/contrato?id=' + data.id_contrato"
                                      style="color: darkblue; cursor: pointer"
                                      >{{ data.id_contrato }}</a
                                    >
                                  </b>
                                  - Serviço
                                  <b>{{ data.id }} - {{ data.tipo }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                class="mt-1"
                                vs-type="flex"
                                vs-justify="flex-start"
                                v-if="data.obs"
                                vs-align="flex-start"
                              >
                                <span class="item-description text-sm">
                                  Obs:
                                  <b>{{ data.obs ? data.obs : "Sem obs" }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span
                                  class="item-description text-sm"
                                  v-if="data.pessoa_confirmacao"
                                >
                                  Pessoa confirmação:
                                  <b>{{ data.pessoa_confirmacao }}</b>
                                </span>
                              </vs-col>
                            </vs-col>
                            <vs-col
                              vs-w="4"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <div class="w-full">
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <Badge
                                    v-if="data.executado > 0"
                                    :text="
                                      data.executado == 1
                                        ? 'Executado'
                                        : 'Não Exec.'
                                    "
                                    :color="
                                      data.executado == 1 ? 'success' : 'danger'
                                    "
                                  ></Badge>
                                </vs-col>
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <Badge
                                    v-if="data.hora_marcada"
                                    :text="data.hora_marcada"
                                    color="warning"
                                  ></Badge>
                                  <Badge
                                    v-else
                                    :text="data.turno"
                                    color="warning"
                                  ></Badge>
                                </vs-col>
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <Badge
                                    v-if="data.fog > 0 || data.escada > 0"
                                    :text="
                                      (data.escada > 0 ? 'Escada' : '') +
                                        (data.escada > 0 && data.fog > 0
                                          ? ' e '
                                          : '') +
                                        (data.fog > 0 ? 'Fog' : '')
                                    "
                                    color="danger"
                                  ></Badge>
                                </vs-col>
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <Badge
                                    v-if="data.carros > 0"
                                    :text="data.carros + ' carros'"
                                    color="#10163a"
                                  ></Badge>
                                </vs-col>
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <Badge
                                    v-if="data.nomeZona"
                                    :text="data.nomeZona"
                                    color="success"
                                  ></Badge>
                                </vs-col>
                              </div>
                            </vs-col>
                          </div>
                        </div>
                      </div>
                    </template>
                  </vx-card>
                </div>
              </draggable>
            </div>
          </VuePerfectScrollbar>
        </div>
      </vs-col>
    </vs-row>
    <vx-card class="mt-1" no-shadow card-background="dark">
      <vs-row
        vs-type="flex"
        class
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="12"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="1"
          class
        >
          <excelOs :rotas="rotasLista" :filtro="filtro" @noData="noData">
            <b style="font-size: 16px">Excel</b>
          </excelOs>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="6"
          class
        >
          <vs-col vs-w="4" vs-justify="flex-end" vs-align="flex-end">
            <div class="mt-1 mx-2">
              <b-form-file
                placeholder="Escolha um Arquivo"
                id="file-small"
                size="md"
                @change="previewFiles"
              ></b-form-file>
            </div>
          </vs-col>
          <vs-button
            icon="add"
            @click="associarComArquivo"
            color="primary"
            type="line"
            size="small"
          >
            <b style="font-size: 16px">Associar com arquivo</b>
          </vs-button>
          <vs-button
            icon="add"
            @click="desassociar"
            color="danger"
            type="line"
            size="small"
          >
            <b style="font-size: 16px">Desassociar rotas</b>
          </vs-button>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>
<script>
import rotaLista from "../rotas/components/rotaLista.vue";
import draggable from "vuedraggable";
import components from "@/components/default/exports.js";
import VisualizarCliente from "./../cliente/visualizar.vue";
import excelOs from "./components/excelOs";

export default {
  data() {
    return {
      turnos: [
        { id: null, nome: "Nenhum" },
        { id: 1, nome: "Manhã" },
        { id: 2, nome: "Almoço" },
        { id: 3, nome: "Tarde" },
        { id: 4, nome: "Noite" },
        { id: 5, nome: "Madrugada" }
      ],
      cardClass: "os-card overflow-hidden",
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      },
      filtro: {
        dataHoje: this.$formartData.dataFiltro(),
        data: null,
        turnos: []
      },
      file: null,
      osAssociar: [],
      osAssociarLista: [],
      osAssociarListaCount: 2,
      osRota: [],
      osRotaOriginal: [],
      rota: [],
      reRender: 1,
      blockButton: false,
      rotasLista: [],
      zonas: [],
      controles: [],
      tipos_servico: []
    };
  },
  async mounted() {
    this.filtro.data = await this.$formartData.formatar(new Date(), 1);
    await this.getOsAssociar();
    await this.getZonas();
    await this.getControles();
    await this.getTiposServico();
  },
  methods: {
    previewFiles(event) {
      this.file = event.target.files[0];
    },
    async associarComArquivo() {
      this.$vs.loading();
      try {
        let formData = new FormData();
        formData.append("file", this.file);
        await this.$http.post("osAssociarRotaComArquivo", formData);
        await this.getOsAssociar();
        await this.getOsByRota();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      this.$vs.loading.close();
    },
    async salvarOrdem() {
      for (let i in this.osRota) {
        this.osRota[i].ordem = i;
      }
    },
    async osAssociarRota() {
      this.$vs.loading();
      let id = this.rota.id;
      try {
        await this.salvarOrdem();
        await this.$http.post("osAssociarRota", { osRota: this.osRota, id });
        this.osRotaOriginal = this.osRota;
        this.osAssociar = await this.$http.post(`getOsAssociar`, this.filtro);
        this.osAssociarLista = this.osAssociar; //.slice(0, 10);
        this.$vs.notify(this.$notify.Success);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      this.$vs.loading.close();
    },
    async getOsAssociar() {
      await this.$vs.loading({ container: "#osAssociar", scale: 0.6 });
      try {
        this.osAssociar = await this.$http.post(`getOsAssociar`, this.filtro);
        this.osAssociarLista = this.osAssociar; //.slice(0, 10);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#osAssociar > .con-vs-loading");
    },
    async rotaSelecionada(rota) {
      this.rota = rota;
      await this.getOsByRota();
    },
    async getOsByRota() {
      await this.$vs.loading({ container: "#osRota", scale: 0.6 });
      try {
        this.osRota = await this.$http.post(
          `getOsByRota/` + this.rota.id,
          this.filtro
        );
        this.osRotaOriginal = this.osRota;
        this.reRender = (await this.reRender) + 1;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#osRota > .con-vs-loading");
      }
    },
    async showMoreOs2Assoc() {
      return 0;
      if (
        this.osAssociar.length > 10 &&
        this.osAssociar.length > this.osAssociarLista.length
      ) {
        this.$vs.loading();
        await setTimeout(() => {
          this.osAssociarLista = this.osAssociar.slice(
            0,
            10 * this.osAssociarListaCount
          );
          this.osAssociarListaCount += 1;
          this.$vs.loading.close();
        }, 200);
      }
    },
    async filtrar() {
      this.blockButton = true;
      try {
        await this.getOsAssociar();
        if (this.rota.id != null) {
          await this.getOsByRota();
        }
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.blockButton = false;
        this.$vs.loading.close();
      }
    },
    async getZonas() {
      try {
        this.zonas = await this.$http.get(`zona`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getControles() {
      try {
        this.controles = await this.$http.get("controle");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async desassociar() {
      this.$vs.loading();

      try {
        await this.$http.post("desassociar_rotas");
        await this.getOsAssociar();
        await this.getOsByRota();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      this.$vs.loading.close();
    },
    async exportToExcel() {
      try {
        import("@/vendor/Export2Excel").then(async excel => {
          let headerTitle = [
            "ID ROTA",
            "OS",
            "ID CONTRATO",
            "TECNICOS EXECUÇÃO",
            "TURNO",
            "HORA MARCADA",
            "NOME CLIENTE",
            "TIPO SERVIÇO",
            "CONTROLE",
            "HORAS EXECUÇÃO",
            "BAIRRO",
            "PESSOA CONFIRMAÇÃO",
            "ENDEREÇO COMPLETO",
            "OBS",
            "CONFIRMADO"
          ];
          let headerVal = [
            "id_rota",
            "id",
            "id_contrato",
            "tecnicos_exec",
            "turno",
            "hora_marcada",
            "nomeCliente",
            "tipo",
            "controle",
            "horas_exec",
            "nomeBairro",
            "pessoa_confirmacao",
            "endereco",
            "obs",
            "msg_confirmado"
          ];
          const date = await this.$formartData.formatar(new Date());
          const list = this.osAssociarLista;
          const data = await this.formatJson(headerVal, list);

          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: `OS_${date}`,
            autoWidth: false,
            bookType: "xlsx"
          });
        });
        this.$vs.loading.close();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          return v[j];
        })
      );
    },
    async getTiposServico() {
      try {
        this.tipos_servico = await this.$http.get("os_tipos");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async noData() {
      this.$vs.notify({
        title:
          this.filtro.turnos > 0
            ? "Sem Dados para Inserir no Excel!"
            : "Selecione um Turno",
        text: "Use o Filtro para Encontrar Serviços",
        color: "danger"
      });
    },
    async rotaEmit(rotas) {
      this.rotasLista = await rotas;
    }
  },
  components: {
    ...components,
    draggable,
    rotaLista,
    VisualizarCliente,
    excelOs
  }
};
</script>
<style scoped lang="scss">
.list-item {
  background-color: #fff;
  transition: background 0.1s ease-in-out;
  border-radius: 3px;
  font-size: 16px;
}
.list-item:hover {
  background: #dcdcdc;
}
.active-list-item {
  background-color: #000a12;
  transition: background 0.1s ease-in-out;
  border-radius: 3px;
  font-size: 16px;
  color: #ffca00;
}
.os-card {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.scroll-os-assoc {
  height: 70vh !important;
}
</style>
