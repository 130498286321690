<template>
  <vs-button
    icon="add"
    @click="prepareObjtsExcel()"
    color="success"
    type="line"
    size="small"
  >
    <b style="font-size: 16px">Excel</b>
  </vs-button>
</template>

<script>
import XLSX from "xlsx/xlsx";
window.$ = window.jQuery = require("jquery");

export default {
  name: "vue-excel-xlsx",

  props: {
    rotas: {
      type: Array,
    },
    filtro: {
      type: Object,
    },
  },
  data() {
    return {
      dataServicos: [],
      dataTecnicos: [],
      columns: [],
      servicos: [],
      tecnicos: [],
      filename: "",
      sheetname: "",
      turnos: [
        { id: null, nome: "Nenhum" },
        { id: 1, nome: "Manhã" },
        { id: 2, nome: "Almoço" },
        { id: 3, nome: "Tarde" },
        { id: 4, nome: "Noite" },
        { id: 5, nome: "Madrugada" },
      ],
    };
  },

  methods: {
    async exportExcel() {
      let createXLSLFormatObj = [],
        cabecalho = [],
        titulo = [],
        newXlsHeader = [],
        restoTecnicos = 0;
      for (let i in this.columns) {
        newXlsHeader.push(this.columns[i].label);
      }
      //MOUNT TITLE
      titulo.push(null);
      titulo.push(null);
      titulo.push(null);
      let tituloString = `ROTAS - ${await this.$formartData.formatar(
        new Date(this.filtro.data + "T00:00:00"),
        2
      )}`;
      titulo.push(tituloString);
      createXLSLFormatObj.push(titulo);
      createXLSLFormatObj.push(null);
      /////////////
      //MOUNT HEADER
      for (let i in this.rotas) {
        if (this.dataServicos[i] && this.dataServicos[i].length > 0) {
          let rota = this.rotas[i];
          cabecalho = [parseInt(i) + 1, rota.nome];
          for (let j in this.dataTecnicos[i]) {
            let obj = this.dataTecnicos[i][j];
            cabecalho.push(obj.nome);
          }
          restoTecnicos =
            4 -
            parseInt(this.dataTecnicos[i] ? this.dataTecnicos[i].length : 0);
          for (let count = 0; count < restoTecnicos; count++) {
            cabecalho.push(null);
          }
          cabecalho.push("VEICULO");
          createXLSLFormatObj.push(cabecalho);
          /////////////
          //MOUNT CONTENT
          createXLSLFormatObj.push(newXlsHeader);
          for (let j in this.dataServicos[i]) {
            let obj = this.dataServicos[i][j];
            let innerRowData = [];
            for (let k in this.columns) {
              let val = this.columns[k];
              if (val.dataFormat && typeof val.dataFormat === "function") {
                innerRowData.push(val.dataFormat(obj[val.field]));
              } else {
                innerRowData.push(obj[val.field]);
              }
            }
            createXLSLFormatObj.push(innerRowData);
          }
          createXLSLFormatObj.push(null);
          /////////////
        }
      }
      let filename = this.filename + ".xlsx";

      let ws_name = this.sheetname;

      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, filename);
    },
    async prepareObjtsExcel() {
      this.servicos = [];
      this.dataServicos = [];
      this.dataTecnicos = [];
      this.data = [];
      this.filename = `ROTAS ${await this.$formartData.formatar(
        new Date(this.filtro.data + "T00:00:00"),
        2
      )}`;
      this.sheetname = "sheetname";
      this.columns = [
        {
          label: "Qtd.",
          field: "qtd",
        },
        {
          label: "Horario",
          field: "horario",
        },
        {
          label: "Nome Cliente",
          field: "nome_cliente",
        },
        {
          label: "Serviço",
          field: "servico",
        },
        {
          label: "Qtd. Hora",
          field: "qtd_hora",
        },
        {
          label: "Bairro",
          field: "bairro",
        },
        {
          label: "Tipo Serviço",
          field: "controle",
        },
      ];
      try {
        for (let i in this.rotas) {
          let rota = this.rotas[i];
          let objServicos = [],
            objTecnicos = [];

          await this.getServicosExcel(rota.id);
          await this.getTecnicosByRota(rota.id);

          for (let j in this.tecnicos) {
            objTecnicos.push({
              nome: this.tecnicos[j].nome,
            });
          }
          for (let j in this.servicos) {
            let os = this.servicos[j];
            objServicos.push({
              qtd: parseInt(j) + 1,
              horario: os.hora_marcada
                ? os.hora_marcada
                : this.turnos[os.id_turno].nome,
              nome_cliente: os.nomeCliente,
              servico: os.tipo,
              qtd_hora: os.horas_exec,
              bairro: os.nomeBairro,
              controle: os.controle,
            });
          }
          if (this.servicos.length > 0) {
            this.dataServicos[i] = objServicos;
          }
          if (this.tecnicos.length > 0) {
            this.dataTecnicos[i] = objTecnicos;
          }
        }
        await this.exportExcel();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getServicosExcel(id_rota) {
      try {
        this.servicos = await this.$http.post(
          `getOsByRota/${id_rota}`,
          this.filtro
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getTecnicosByRota(id_rota) {
      try {
        this.tecnicos = await this.$http.get(`getTecByRota/${id_rota}`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
  },
};
</script>
